import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store' // short for @/store/index
// import the plugin
import VueGapi from 'vue-gapi'


// const config = {
//   apiKey: 'AIzaSyATrRjxHaKPFxqDaJMTq_w2nLqju0tGVfMy',
//   clientId: '463566341686-4amostrbhk2ed69b3tp33h6tfsvhfggh.apps.googleusercontent.com',
//   scope: 'https://www.googleapis.com/auth/spreadsheets',
//   discoveryDocs: [ 'https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest' ]
// }

const app = createApp(App)
app.use(VueAxios, axios)
app.use(router)
app.use(store)
app.use(VueGapi, {
  apiKey: 'AIzaSyATrRjxHaKPFxqDaJMTq_w2nLqju0tGVfM',
  clientId: '463566341686-1he24r2ftlr2g75jcjtj4hisarffbhqs.apps.googleusercontent.com',
  discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  scope: 'https://www.googleapis.com/auth/spreadsheets',
})
app.mount('#app')




 

