<template>
  <div class="row fixed-top black pb-3">
    <nav class="navbar navbar-expand-lg navbar-light pl-0 w-100">
      <div class="col-4">
        <router-link class="nav-link p-0 pl-4 pt-0" to="/dashboard"
          ><img
            src="../assets/amerus-logo.png"
            class="img-fluid float-left"
            alt=""
            width="100"
        /></router-link>
      </div>

      <div class="col-8">
        <div class="" id="">
          <div class="float-right">
            <router-link class="nav-link float-left" to="/profile">
              <img
                :src="profileImage"
                :alt="name"
                class="rounded-circle"
                width="35"
              />
            </router-link>
            <!-- <router-link class="nav-link float-left" to="/messages">
          <i class="fas fa-comment-alt"></i>
        </router-link> -->

            <a
              class="nav-link float-left"
              href="https://amerusfinancialgroup.com/agent-login/"
              target="_blank"
            >
              <i class="fas fa-store"></i>
            </a>

            <router-link class="nav-link float-left" to="/" @click="logout">
              <i class="fas fa-sign-out"></i>
            </router-link>

            <!-- <router-link class="nav-link" to="/cart">
            <i class="fas fa-shopping-cart"></i>
          </router-link> -->
          </div>
        </div>
      </div>
    </nav>
    <HorizontalToolBar />
  </div>
</template>


<script>
import HorizontalToolBar from "../components/HorizontalToolBar";

export default {
  components: {
    HorizontalToolBar,
  },
  methods: {
    logout() {
      this.$store.dispatch("logOut");
    },
  },
  computed: {
    name() {
      return this.$store.getters.getName;
    },
    profileImage() {
      return this.$store.getters.getProfileImage;
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: #ffffff !important;
}
.fa-user-circle {
  font-size: 2em;
}
.fa-comment-alt,
.fa-shopping-cart,
.fa-store,
.fa-sign-out {
  font-size: 1.5rem;
  padding: 0.5rem;
  color: #f4d384;
  text-shadow: 1px 1px #000000;
}
.border-bottom {
  /* border-bottom: #fff thin solid !important; */
  border-bottom: #ffe29b thin solid !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.small {
  font-size: 0.8rem;
}
</style>